import React from "react";
import SignaturePad from "react-signature-canvas";

class StepTwo extends React.Component {
  sigPad = {};
  initialsPad = {};

  clear = (e) => {
    e.preventDefault();
    this.props.clear("Step2").then(() => this.sigPad.clear());
  };

  trim = () => {
    this.props.trim(this.sigPad.getCanvas().toDataURL("image/png"), "Step2");
  };

  clearInitials = (e) => {
    e.preventDefault();
    this.props.clearInitials("Step2").then(() => this.initialsPad.clear());
  };

  trimInitials = () => {
    this.props.trimInitials(
      this.initialsPad.getCanvas().toDataURL("image/png"),
      "Step2"
    );
  };

  render() {
    const value = `Tuition Payment:
Tuition must be paid in full by the tuition payment deadline as agreed in the Student Financial Contract (Form 103). All other fees for application, placement test, and books must be paid prior to classes starting and are non-refundable. Failure to pay the full amount of tuition or arrange other financial support prior to the due date may result in a late flat fee of $50.00.

Tuition Refund:
Students requesting a refund must send a written request to the Student Financial Services by email (finance@lumos.edu). The refund will be made to the student within 30 days in the form of a check. The student will be notified by email when the check is ready to be picked up. Student’s failure to notify the appropriate parties in writing of withdrawal may delay the tuition re- fund. The school will consider the Date of Enrollment (DE) whatever comes first of the following: 1) The day the student signs an enrollment agreement; 2) the day the student pays the institution an initial deposit or first payment toward tuition and fees; 3) the day that the student first visits the institution if the program lasts more than 30 consecutive calendar days.

The percentage of tuition refund is based on the full amount of the semester tuition, and not based on the amount paid.

Refund Schedule based on Date of Enrollment. The student is entitled to refund as follow:
•	Until 3 Days within Date of Enrollment = 100%
•	Until 4 Days within Date of Enrollment = 50%
•	Until 5 Days within Date of Enrollment = 25%
•	After the 5th day of Date of Enrollment = 0%

Requests sent after the 5th day of the DE will not qualify for any refund.

By signing this Financial Agreement, I acknowledge that I have read and understand the applicable fees and fi- nancial responsibilities of Lumos Language School, as stated on the back of this contract, and agree to abide by all policies and pay all fees accordingly. I understand that all registration fees are nonrefundable nor transferable and that the Financial Agreement is a commitment for the period of time contracted.

Amendments to Policies
Lumos Language School hereby reserves the right to modify its policies at any given time. Such modifications will be promptly communicated to all enrolled students and will be available in the Student Handbook.

Program/Class Cancellation:
If our institution cancels a program after a student’s registration, we will refund all fees paid by the student. The institution holds the right to make class closures if the number of students enrolled in each class does not reach minimum capacity. In such cases, the institution will provide other options when available for students to make changes on their schedule due to class closures.

Attendance:
International F-1 students have legal obligations they must fulfill in order to maintain status.
The U.S. Government, through federal laws, has determined that a full course of study for an F-1 student consists of at least eighteen clock hours of attendance a week if the dominant part of the course of study consists of classroom instruction. (C.F.R. 214.2(f)(6)(i)(F)). Also, the U.S. Government allows each English program to determine its own attendance policy that will ensure that students fulfill their legal obligations and complete a full course of study while enrolled.

Lumos Language School policy expects regular and punctual attendance in all courses. Students are required to maintain a minimum of 80% attendance of enrolled programs.
        `;

    const value2 = `
Zero Tolerance of Disrespect Towards Faculty, Staff, and Students:
Any perspective or enrolled student at Lumos, who shows disrespect to Lumos students, any member of staff or faculty can be subject to disciplinary action such as loss of attendance and/or removal from the pro- gram. Disrespect in this Handbook is defined by (but not excluded to) the following:
Constant class disruption, rudeness, unnecessary and excessive argumentation with other students or staff and faculty, harassment, excessive complaining, yelling or threatening speech, physical violence, verbal threats of violence, disregard or refusal of faculty direction, refusal to participate in classroom activities, overall disrespect and disregard for Staff and Faculty efforts and direction.
Any student or prospective student may be removed from the program and not be allowed to register and attend the programs offered at Lumos due to violating the Zero-Tolerance of Disrespect policy.

Lumos students are required to maintain certain standards of behavior while they attend our program which encourages an atmosphere of learning and mutual respect

I understand and agree with the school policies above described and I have been given ample opportunity to review and understand the terms and conditions of enrollment, including the institution’s refund policy, prior to signing the enrollment documents
        `;

    let tuition = 0;

    let course = null;

    if (this.props.Courses.length == 1) {
      course = this.props.Courses[0];
    } else {
      course = this.props.Courses.find(
        (x) => x.order == this.props.Subject?.courseOrder
      );
    }

    tuition = course && course.newTuitionCost;

    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    let applyDiscountRule = false; //ativar no proximo self-registration, pra mostrar o desconto

    return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>
                <span className="step-no bottom-line">
                  Financial Information
                </span>
                <div className="step-progress float-right">
                  <span>2 of 2 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <>
                  <h2>
                    How would you like to pay your tuition for the next
                    semester? <br />
                    Choose one of the options below.
                  </h2>
                  <div className="services-select-option5">
                    <ul>
                      <div
                        onClick={this.props.step2FinanceRender.bind(this, 1)}
                      >
                        <li className="bg-white customRadio">
                          <label>
                            <h3>
                              {"One payment of " +
                                USDollar.format(tuition + tuition * 0.04)}
                            </h3>
                          </label>

                          <label
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                          >
                            {applyDiscountRule && (
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "5px 8px",
                                  borderRadius: "5px",
                                  fontSize: "1rem",
                                }}
                              >
                                (no discount)
                              </span>
                            )}

                            <input type="radio" name="finance" />
                          </label>
                        </li>
                      </div>

                      {applyDiscountRule && (
                        <div
                          onClick={this.props.step2FinanceRender.bind(this, 3)}
                        >
                          <li className="bg-white customRadio">
                            <label>
                              <h3>
                                {"One payment of " +
                                  USDollar.format(
                                    tuition - 100 + (tuition - 100) * 0.04
                                  )}
                              </h3>
                            </label>

                            <label>Due by December 10, 2024</label>

                            <label
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "5px 8px",
                                  borderRadius: "5px",
                                  fontSize: "1rem",
                                }}
                              >
                                (U$100.00 discount included)
                              </span>
                              <input type="radio" name="finance" />
                            </label>
                          </li>
                        </div>
                      )}

                      <div
                        onClick={this.props.step2FinanceRender.bind(this, 4)}
                      >
                        <li className="bg-white customRadio">
                          <label>
                            <h3>
                              {applyDiscountRule &&
                                "Down payment of " +
                                  USDollar.format(
                                    (tuition - 100) / 2 +
                                      ((tuition - 100) / 2) * 0.04
                                  )}

                              {!applyDiscountRule &&
                                "Two Payments (" +
                                  USDollar.format(
                                    tuition / 2 + (tuition / 2) * 0.04
                                  ) +
                                  " + " +
                                  USDollar.format(
                                    tuition / 2 + (tuition / 2) * 0.04
                                  ) +
                                  ")"}
                            </h3>
                          </label>

                          {applyDiscountRule && (
                            <label>
                              {"Due by December 10, 2024 + 1 installment of " +
                                USDollar.format(
                                  (tuition - 100) / 2 +
                                    ((tuition - 100) / 2) * 0.04
                                )}
                            </label>
                          )}

                          <label
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                          >
                            {applyDiscountRule && (
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "5px 8px",
                                  borderRadius: "5px",
                                  fontSize: "1rem",
                                }}
                              >
                                (U$100.00 discount included)
                              </span>
                            )}
                            <input type="radio" name="finance" />
                          </label>
                        </li>
                      </div>

                      {applyDiscountRule && (
                        <div
                          onClick={this.props.step2FinanceRender.bind(this, 5)}
                        >
                          <li className="bg-white customRadio">
                            <label>
                              <h3>
                                {"Down payment of " +
                                  USDollar.format(
                                    (tuition - 100) / 2 +
                                      ((tuition - 100) / 2) * 0.04
                                  )}
                              </h3>
                            </label>

                            <label>
                              {"Due by December 10, 2024 + 2 installments of " +
                                USDollar.format(
                                  (tuition - 100) / 4 +
                                    ((tuition - 100) / 4) * 0.04
                                )}
                            </label>

                            <label
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "5px 8px",
                                  borderRadius: "5px",
                                  fontSize: "1rem",
                                }}
                              >
                                (U$100.00 discount included)
                              </span>
                              <input type="radio" name="finance" />
                            </label>
                          </li>
                        </div>
                      )}

                      <div
                        onClick={this.props.step2FinanceRender.bind(this, 2)}
                      >
                        <li className="bg-white customRadio">
                          <label>
                            <h3>
                              {"Three Payments (" +
                                USDollar.format(
                                  tuition / 2 + (tuition / 2) * 0.04
                                ) +
                                " + " +
                                USDollar.format(
                                  tuition / 4 + (tuition / 4) * 0.04
                                ) +
                                " + " +
                                USDollar.format(
                                  tuition / 4 + (tuition / 4) * 0.04
                                ) +
                                ")"}
                            </h3>
                          </label>

                          <label
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                          >
                            {applyDiscountRule && (
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "5px 8px",
                                  borderRadius: "5px",
                                  fontSize: "1rem",
                                }}
                              >
                                (no discount)
                              </span>
                            )}

                            <input type="radio" name="finance" />
                          </label>
                        </li>
                      </div>
                    </ul>
                  </div>
                </>

                <h2>Terms and Conditions</h2>

                <div
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    fontFamily: "Arial, sans-serif",
                    width: "100%",
                    minHeight: "200px",
                    overflowY: "hidden",
                  }}
                >
                  <textarea
                    className="termsx"
                    disabled={true}
                    value={value}
                  ></textarea>

                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "7px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Absences
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          School Action
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          School Standing
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Sevis Standing
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          0-15
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          None
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          16-23
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Warning Email
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          24 and up
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Termination Letter
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Terminated
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Terminated*
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <textarea
                    className="termsx2"
                    disabled={true}
                    value={value2}
                  ></textarea>
                </div>

                <div className="budget-area">
                  <div
                    className="opti-list"
                    onClick={this.props.handleCheckbox.bind(this, "Step2", 1)}
                  >
                    <ul className="d-md-flex">
                      <li className="bg-white ">
                        <input
                          type="checkbox"
                          name="code_opti1"
                          value="Semantic coding"
                        />
                        I accept the Terms and Conditions.
                      </li>
                    </ul>
                  </div>

                  <br />

                  <div
                    className="row form-inner-area"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">Signature</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label float-right">
                        <button
                          color="danger"
                          className="btn btn-danger "
                          onClick={this.clear.bind(this)}
                        >
                          Clear Signature
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row form-inner-area">
                    <div className="col-md-12">
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{
                            className: "sigPad",
                          }}
                          ref={(ref) => {
                            this.sigPad = ref;
                          }}
                          onEnd={this.trim.bind(this)}
                          clearOnResize={false}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="row form-inner-area"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="col-md-2">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">Initials</label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="wizard-form-input position-relative form-group has-float-label float-right">
                        <button
                          color="danger"
                          className="btn btn-danger "
                          onClick={this.clearInitials.bind(this)}
                        >
                          Clear Initials
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row form-inner-area">
                    <div className="col-md-4">
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{
                            className: "sigPad",
                          }}
                          ref={(ref) => {
                            this.initialsPad = ref;
                          }}
                          onEnd={this.trimInitials.bind(this)}
                          clearOnResize={false}
                        />
                      </div>
                    </div>
                  </div>

                  <span
                    style={{
                      fontStyle: "italic",
                      color: "gray",
                      textAlign: "justify",
                    }}
                  >
                    By signing, I hereby certify that the above information, to
                    the best of my knowledge, is correct. I understand and agree
                    to abide by all the provisions set forth in the foregoing
                    enrollment agreement as presented in the school catalog.
                  </span>

                  {this.props.data.ErrorSubmit.length > 0 && (
                    <div
                      className="row form-inner-area"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <div class="alert alert-danger" role="alert">
                            {this.props.data.ErrorSubmit}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="actions">
            <ul>
              <li>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left"></i> BACK{" "}
                </span>
              </li>
              <li>
                <span
                  className={`
                  ${
                    this.formValid() &&
                    this.props.data.SubmittingApplication === false
                      ? ""
                      : "button-disabled"
                  }`}
                  title="SUBMIT"
                  disabled={this.props.data.SubmittingApplication === true}
                  onClick={this.props.handleSubmit.bind(this)}
                >
                  SUBMIT <i className="fa fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.FinanceId > -1 &&
      this.props.data.Agree1 === true &&
      this.props.data.trimmedDataURL !== null &&
      this.props.data.trimmedDataURL.length > 0 &&
      this.props.data.trimmedDataURLInitials !== null &&
      this.props.data.trimmedDataURLInitials.length > 0
    );
  }
}

export default StepTwo;
