import React from "react";
import Step1 from "./step/step-1";
import Step2 from "./step/step-2";
import logo from "../assets/img/foto_apply.jpg";
import swal from "sweetalert";
import * as dateFns from "date-fns";
// import Countries from "../Countries.json";

class VersionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.ErrorMessage = this.ErrorMessage.bind(this);
  }

  getModalState = () => {
    return {
      ApplicationObj: {
        Step1: {
          I20Number: "",
          DateOfBirth: null,
          Name: "",
          CurrentLevel: "",
          CurrentCampus: "",
          CurrentSemester: "",
          SemesterStartDate: null,
          CurrentStatus: "",
          PlanId: -1,
          LevelId: -1,
          CampusId: -1,
          PeriodId: -1,
          CurrentOrder: -1,
          ErrorMsgValidate: "",
          Courses: [],
          StudentClasses: [],
          ValidatingStudent: false,
          OtherReason: "",
          LevelReason: "",
          Failed: false,
          InvalidEmail: false,
          Email: "",
          PhoneNumber: "",
          PhoneNumberIsValid: true,
          Street: "",
          Street2: "",
          City: "",
          State: "",
          Zip: "",
          Country: "United States",
          trimmedDataURL: null,
          trimmedDataURLInitials: null,
          SubmittingApplication: false,
          ErrorSubmit: "",
          IsNonF1: false,
        },
        Step2: {
          FinanceId: -1,
          trimmedDataURL: null,
          trimmedDataURLInitials: null,
          Agree1: false,
          SubmittingApplication: false,
          ErrorSubmit: "",
        },
      },
    };
  };

  render() {
    return (
      <div className="wrapper">
        <div className="steps-area steps-area-fixed">
          <div className="image-holder">
            <img src={logo} alt="" />
          </div>
          <div className="steps clearfix">
            <ul className="tablist multisteps-form__progress">
              <li className="multisteps-form__progress-btn js-active current">
                <span>1</span>
              </li>
              <li className="multisteps-form__progress-btn last">
                <span>2</span>
              </li>
            </ul>
          </div>
        </div>
        <form
          className="multisteps-form__form"
          action="#"
          id="wizard"
          method="POST"
        >
          <div className="form-area position-relative">
            <Step1
              data={this.state.ApplicationObj.Step1}
              planRender={this.planRender.bind(this)}
              campusRender={this.campusRender.bind(this)}
              periodRender={this.periodRender.bind(this)}
              levelRender={this.levelRender.bind(this)}
              applicationObj={this.state.ApplicationObj}
              validateStudent={this.validateStudent}
              handleUserInput={this.handleUserInput.bind(this)}
              handleSubmitBreak={this.handleSubmitBreak.bind(this)}
              emailOnBlur={this.emailOnBlur.bind(this)}
              clear={this.clear}
              clearInitials={this.clearInitials}
              trim={this.trim}
              trimInitials={this.trimInitials}
              handleSelectStateChange={this.handleSelectStateChange.bind(this)}
              handlePhoneNumberChange={this.handlePhoneNumberChange.bind(this)}
              // Countries={Countries}
            />
            <Step2
              data={this.state.ApplicationObj.Step2}
              step2FinanceRender={this.step2FinanceRender.bind(this)}
              clear={this.clear}
              clearInitials={this.clearInitials}
              trim={this.trim}
              trimInitials={this.trimInitials}
              handleCheckbox={this.handleCheckbox.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              LevelId={this.state.ApplicationObj.Step1.LevelId}
              IsNonF1={this.state.ApplicationObj.Step1.IsNonF1}
              Courses={this.state.ApplicationObj.Step1.Courses}
            />
          </div>
        </form>
      </div>
    );
  }

  planRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.PlanId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  campusRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.CampusId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  periodRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.PeriodId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  levelRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.LevelId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step2CampusRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step2 = ApplicationObj.Step2;

        Step2.CampusId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step2PeriodRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step2 = ApplicationObj.Step2;

        Step2.PeriodId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleUserInput = (step, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[step];

        obj[e.target.name] = e.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  clear = (parent) => {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let obj = ApplicationObj[parent];

          obj.trimmedDataURL = null;

          return { ApplicationObj };
        },

        resolve(true)
      );
    });
  };

  clearInitials = (parent) => {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let obj = ApplicationObj[parent];

          obj.trimmedDataURLInitials = null;

          return { ApplicationObj };
        },

        resolve(true)
      );
    });
  };

  trim = (canvas, parent) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        obj.trimmedDataURL = canvas;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  trimInitials = (canvas, parent) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        obj.trimmedDataURLInitials = canvas;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  getPlanInitial() {
    let id = this.state.ApplicationObj.Step1.PlanId;

    return id === 1
      ? "S"
      : id === 2
      ? "B"
      : id === 3
      ? "C"
      : id === 4
      ? "O"
      : "";
  }

  getPeriodInitial() {
    let id = this.state.ApplicationObj.Step1.PeriodId;

    return id === 1
      ? "M"
      : id === 2
      ? "E"
      : id === 3
      ? "MT"
      : id === 4
      ? "WT"
      : "";
  }

  getCampusName() {
    let id = this.state.ApplicationObj.Step1.CampusId;

    return id === 1
      ? "Online Combined Campuses"
      : id === 2
      ? "Orem In Person"
      : id === 3
      ? "Salt Lake in Person"
      : "";
  }

  isValidating(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.ValidatingStudent = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  isSubmitting(bool, parent) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        obj.SubmittingApplication = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  validateStudent = () => {
    if (
      this.state.ApplicationObj.Step1.I20Number.length > 0 &&
      this.state.ApplicationObj.Step1.DateOfBirth.length > 0
    ) {
      this.isValidating(true).then(() => {
        const self = this;

        const requestOptions = {
          crossDomain: true,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        return fetch(
          url +
            "/selfenroll/validatestudent?i20=" +
            encodeURIComponent(self.state.ApplicationObj.Step1.I20Number) +
            "&dateofbirth=" +
            encodeURIComponent(self.state.ApplicationObj.Step1.DateOfBirth),
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                if (data.success === true) {
                  obj.Name = data.data.name;
                  obj.CurrentLevel = data.data.courseName;
                  obj.CurrentOrder = data.data.courseOrder;
                  obj.CurrentSemester = data.data.semesterName;
                  obj.SemesterStartDate = dateFns.parseISO(
                    data.data.semesterStartDate
                  );
                  obj.CurrentCampus = data.data.campusName;
                  obj.CurrentStatus = data.data.statusList[0].statusType.name;
                  obj.Failed = data.data.failed;
                  obj.IsNonF1 = data.data.visaType.name !== "Student F1";

                  obj.Courses = [];
                  data.data.courseList.map((course, index) => {
                    obj.Courses.push({
                      id: course.id,
                      order: course.order,
                      name: course.name,
                      tuitionCost: course.tuitionCost,
                      newTuitionCost: course.newTuitionCost,
                    });
                  });

                  obj.StudentClasses = [];
                  data.data.studentClasses.map((sc, index) => {
                    obj.StudentClasses.push({
                      code: sc.classe.code,
                      grade: ((sc.marks / sc.classe.marks) * 100).toFixed(2),
                    });
                  });

                  // obj.I20Number = "";
                  //obj.DateOfBirth = "";
                  obj.ErrorMsgValidate = "";
                } else {
                  let message = "";
                  if (data.messages)
                    message = data.messages.map((error) => error).join("\n");

                  obj.Name = "";
                  obj.CurrentLevel = "";
                  obj.CurrentSemester = "";
                  obj.SemesterStartDate = null;
                  obj.CurrentCampus = "";
                  obj.CurrentStatus = "";
                  obj.Courses = [];
                  obj.StudentClasses = [];
                  obj.IsNonF1 = false;

                  obj.ErrorMsgValidate = message;
                }

                obj.PlanId = -1;
                obj.LevelId = -1;
                obj.CampusId = -1;
                obj.PeriodId = -1;

                obj.ValidatingStudent = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          })
          .catch(function (error) {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                obj.ErrorMsgValidate = "Invalid information";

                obj.Name = "";
                obj.CurrentLevel = "";
                obj.CurrentSemester = "";
                obj.SemesterStartDate = null;
                obj.CurrentCampus = "";
                obj.CurrentStatus = "";
                obj.Courses = [];
                obj.StudentClasses = [];
                obj.IsNonF1 = false;

                obj.ValidatingStudent = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          });
      });
    }
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  finishSubmit() {
    if (this.state.ApplicationObj.Step2.SubmittingApplication === false) {
      this.isSubmitting(true, "Step2").then(async () => {
        const self = this;

        let applyObj = {
          I20: this.state.ApplicationObj.Step1.I20Number,
          Plan: this.getPlanInitial(),
          Period: this.getPeriodInitial(),
          Level: this.state.ApplicationObj.Step1.LevelId,
          Campus: this.getCampusName(),
          FinancialPlan: this.state.ApplicationObj.Step2.FinanceId,
          Email: this.state.ApplicationObj.Step1.Email,
          PhoneNumber: this.state.ApplicationObj.Step1.PhoneNumber,
          Street: this.state.ApplicationObj.Step1.Street,
          Street2: this.state.ApplicationObj.Step1.Street2,
          City: this.state.ApplicationObj.Step1.City,
          State: this.state.ApplicationObj.Step1.State,
          Zip: this.state.ApplicationObj.Step1.Zip,
          OtherReason: this.state.ApplicationObj.Step1.OtherReason,
          LevelReason: this.state.ApplicationObj.Step1.LevelReason,
          DocumentSignature: {
            FileContentBase64: this.state.ApplicationObj.Step2.trimmedDataURL,
            FileName: "Signature.jpg",
          },
          DocumentInitials: {
            FileContentBase64:
              this.state.ApplicationObj.Step2.trimmedDataURLInitials,
            FileName: "Initials.jpg",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(applyObj),
        };

        return fetch(url + "/selfEnroll", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let step2 = ApplicationObj.Step2;

                if (data.success === true) {
                  alert("Thank you for submitting your self-enrollment.");
                  window.location.assign("https://lumos.edu");
                } else if (data.messages) {
                  let message = data.messages.map((error) => error).join("\n");

                  step2.ErrorSubmit = message;
                } else {
                  step2.ErrorSubmit = "An internal server error has occurred.";
                }

                step2.SubmittingApplication = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          })
          .catch(function (error) {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let step2 = ApplicationObj.Step2;

                let msg = self.ErrorMessage(error);
                step2.ErrorSubmit =
                  "A server connection error occurred in your application. Error message: " +
                  msg;

                step2.SubmittingApplication = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          });
      });
    }
  }

  finishSubmitBreak() {
    if (this.state.ApplicationObj.Step1.SubmittingApplication === false) {
      this.isSubmitting(true, "Step1").then(async () => {
        const self = this;

        let applyObj = {
          I20: this.state.ApplicationObj.Step1.I20Number,
          Plan: this.getPlanInitial(),
          Email: this.state.ApplicationObj.Step1.Email,
          PhoneNumber: this.state.ApplicationObj.Step1.PhoneNumber,
          Street: this.state.ApplicationObj.Step1.Street,
          Street2: this.state.ApplicationObj.Step1.Street2,
          City: this.state.ApplicationObj.Step1.City,
          State: this.state.ApplicationObj.Step1.State,
          Zip: this.state.ApplicationObj.Step1.Zip,
          OtherReason: this.state.ApplicationObj.Step1.OtherReason,
          LevelReason: this.state.ApplicationObj.Step1.LevelReason,
          DocumentSignature: {
            FileContentBase64: this.state.ApplicationObj.Step1.trimmedDataURL,
            FileName: "Signature.jpg",
          },
          DocumentInitials: {
            FileContentBase64:
              this.state.ApplicationObj.Step1.trimmedDataURLInitials,
            FileName: "Initials.jpg",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(applyObj),
        };

        return fetch(url + "/selfEnroll", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let step1 = ApplicationObj.Step1;

                if (data.success === true) {
                  alert("Thank you for submitting your self-enrollment.");
                  window.location.assign("https://lumos.edu");
                } else if (data.messages) {
                  let message = data.messages.map((error) => error).join("\n");

                  step1.ErrorSubmit = message;
                } else {
                  step1.ErrorSubmit = "An internal server error has occurred.";
                }

                step1.SubmittingApplication = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          })
          .catch(function (error) {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let step1 = ApplicationObj.Step1;

                let msg = self.ErrorMessage(error);
                step1.ErrorSubmit =
                  "A server connection error occurred in your application. Error message: " +
                  msg;

                step1.SubmittingApplication = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          });
      });
    }
  }

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    this.finishSubmit();
  }

  handleSubmitBreak(event) {
    event.preventDefault();

    this.finishSubmitBreak();
  }

  handleCheckbox = (parent, index, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        obj["Agree" + index] = !obj["Agree" + index];

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  ErrorMessage(error) {
    var message = "";

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.messages) {
          message = error.response.data.messages
            .map((error) => error)
            .join("\n");
        } else {
          message = error.response.data;
        }
      } else {
        message = error.message;
      }
    } else if (error.message) {
      message = error.message;
    }

    return message;
  }

  step2FinanceRender = (id) => {
    let title = "";
    let message = "";

    let tuition = 0;

    let course = null;

    if (this.state.ApplicationObj.Step1.Courses.length == 1) {
      course = this.state.ApplicationObj.Step1.Courses[0];
    } else {
      course = this.state.ApplicationObj.Step1.Courses.find(
        (x) => x.order == this.state.ApplicationObj.Step1.LevelId
      );
    }

    tuition = course && course.newTuitionCost;

    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    switch (id) {
      case 1:
        title = "One Payment";
        message =
          "\n One Payment of " +
          USDollar.format(tuition + tuition * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition) +
          " + Credit Card Fee: " +
          USDollar.format(tuition * 0.04) +
          ")\n\n* Due on the first day of school (" +
          dateFns.format(
            this.state.ApplicationObj.Step1.SemesterStartDate,
            "MM/dd/yyyy"
          ) +
          ")\n\n* If you choose to make any tuition payments in cash, please go in person to the school and the credit/debit card fee for that payment will be removed.";
        break;
      case 2:
        title = "Three Payments";
        message =
          "\n 1st payment of " +
          USDollar.format(tuition / 2 + (tuition / 2) * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition / 2) +
          " + Credit Card Fee: " +
          USDollar.format((tuition / 2) * 0.04) +
          ")\n\n* Due on the first day of school (" +
          dateFns.format(
            this.state.ApplicationObj.Step1.SemesterStartDate,
            "MM/dd/yyyy"
          ) +
          ") \n\n 2nd Payment of " +
          USDollar.format(tuition / 4 + (tuition / 4) * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition / 4) +
          " + Credit Card Fee: " +
          USDollar.format((tuition / 4) * 0.04) +
          ")\n\n* Due 30 days after the first day of school (" +
          dateFns.format(
            dateFns.addMonths(
              this.state.ApplicationObj.Step1.SemesterStartDate,
              1
            ),
            "MM/dd/yyyy"
          ) +
          ") \n\n 3rd Payment of " +
          USDollar.format(tuition / 4 + (tuition / 4) * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition / 4) +
          " + Credit Card Fee: " +
          USDollar.format((tuition / 4) * 0.04) +
          ")\n\n* Due 60 days after the first day of school (" +
          dateFns.format(
            dateFns.addMonths(
              this.state.ApplicationObj.Step1.SemesterStartDate,
              2
            ),
            "MM/dd/yyyy"
          ) +
          ") \n\n* If you choose to make any tuition payments in cash, please go in person to the school and the credit/debit card fee for that payment will be removed.";
        break;

      case 4:
        title = "Two Payments";
        message =
          "\n 1st payment of " +
          USDollar.format(tuition / 2 + (tuition / 2) * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition / 2) +
          " + Credit Card Fee: " +
          USDollar.format((tuition / 2) * 0.04) +
          ")\n\n* Due on the first day of school (" +
          dateFns.format(
            this.state.ApplicationObj.Step1.SemesterStartDate,
            "MM/dd/yyyy"
          ) +
          ") \n\n 2nd Payment of " +
          USDollar.format(tuition / 2 + (tuition / 2) * 0.04) +
          " (Tuition: " +
          USDollar.format(tuition / 2) +
          " + Credit Card Fee: " +
          USDollar.format((tuition / 2) * 0.04) +
          ")\n\n* Due 30 days after the first day of school (" +
          dateFns.format(
            dateFns.addMonths(
              this.state.ApplicationObj.Step1.SemesterStartDate,
              1
            ),
            "MM/dd/yyyy"
          ) +
          ") \n\n* If you choose to make any tuition payments in cash, please go in person to the school and the credit/debit card fee for that payment will be removed.";
        break;
    }

    swal(title, message, "warning").then(() => {
      return new Promise((resolve, reject) => {
        this.setState((prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let Step2 = ApplicationObj.Step2;

          Step2.FinanceId = id;

          return { ApplicationObj };
        }, resolve(true));
      });
    });
  };

  emailIsValid(email) {
    return (
      email.length > 0 && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    );
  }

  emailOnBlur = async (e) => {
    const value = e.target.value;

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.InvalidEmail = !this.emailIsValid(value);

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleSelectStateChange = (event) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.State = event.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handlePhoneNumberChange = (e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        const input = e.target.value;
        const onlyNumbers = input.replace(/\D/g, "");

        if (/^\d{10}$/.test(onlyNumbers) || /^\d{11}$/.test(onlyNumbers)) {
          const formattedPhoneNumber = this.formatPhoneNumber(onlyNumbers);

          Step1.PhoneNumber = formattedPhoneNumber;
          Step1.PhoneNumberIsValid = true;
        } else {
          Step1.PhoneNumber = input;
          Step1.PhoneNumberIsValid = false;
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };
}

export default VersionOne;
