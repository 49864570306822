/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import SignaturePad from "react-signature-canvas";

class StepOne extends React.Component {
  sigPad = {};
  initialsPad = {};

  clear = (e) => {
    e.preventDefault();
    this.props.clear("Step1").then(() => this.sigPad.clear());
  };

  clearInitials = (e) => {
    e.preventDefault();
    this.props.clearInitials("Step1").then(() => this.initialsPad.clear());
  };

  trim = () => {
    this.props.trim(this.sigPad.getCanvas().toDataURL("image/png"), "Step1");
  };

  trimInitials = () => {
    this.props.trimInitials(
      this.initialsPad.getCanvas().toDataURL("image/png"),
      "Step1"
    );
  };

  render() {
    let subjects = this.props.data.Subjects.filter(
      (x) =>
        x.campusName ==
        (this.props.data.CampusId == 2
          ? "Orem In Person"
          : this.props.data.CampusId == 3
          ? "Salt Lake in Person"
          : "")
    ).map((subject, index) => {
      return (
        <div onClick={this.handleScrollSubject.bind(this, subject.id)}>
          <li className="bg-white customRadio">
            <label>
              <h3>
                {subject.levelName + " "}{" "}
                <strong style={{ color: subject.periodColor }}>
                  {subject.periodName}
                </strong>{" "}
              </h3>
            </label>
            <label>{subject.line2}</label>
            <label>
              {subject.line3}{" "}
              <input type="radio" name={"subject" + this.props.data.CampusId} />
            </label>
          </li>
        </div>
      );
    });

    let studentClasses = this.props.data.StudentClasses.map((sc, index) => {
      return (
        <div className="col-md-12">
          <div className="wizard-form-input position-relative form-group has-float-label">
            <label
              style={{ color: sc.grade < 70 ? "red" : "green" }}
              className="label-input"
            >{`${sc.code} : ${sc.grade}%`}</label>
          </div>
        </div>
      );
    });

    let invalidEmail = this.props.data.InvalidEmail === true;
    let phoneNumberIsValid = this.props.data.PhoneNumberIsValid === true;

    return (
      <div
        className="multisteps-form__panel js-active"
        data-animation="slideHorz"
      >
        <div className="wizard-forms">
          <div className="inner clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>

                <span className="step-no bottom-line">Self-registration</span>
                <div className="step-progress float-right">
                  <span>1 of 2 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <h2>Validate the information below</h2>

                <div className="row form-inner-area">
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">I-20 number</label>
                      <input
                        style={{
                          borderColor: this.props.data.Name
                            ? "#28a745"
                            : "#EDEDED",
                        }}
                        type="text"
                        className="form-control "
                        name="I20Number"
                        value={this.props.data.I20Number}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1"
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Date of Birth</label>
                      <input
                        style={{
                          borderColor: this.props.data.Name
                            ? "#28a745"
                            : "#EDEDED",
                        }}
                        type="date"
                        className="form-control"
                        name="DateOfBirth"
                        value={this.props.data.DateOfBirth}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <button
                        type="button"
                        className="btn btn-primary btn-xlarge"
                        onClick={this.props.validateStudent.bind(this)}
                        disabled={
                          this.props.data.I20Number.length === 0 ||
                          !this.props.data.DateOfBirth ||
                          this.props.data.DateOfBirth.length === 0 ||
                          this.props.data.ValidatingStudent === true
                        }
                        style={{
                          cursor:
                            this.props.data.I20Number.length === 0 ||
                            !this.props.data.DateOfBirth ||
                            this.props.data.DateOfBirth.length === 0
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        Validate
                      </button>
                    </div>
                  </div>
                </div>

                {this.props.data.ErrorMsgValidate.length > 0 && (
                  <label className="label-input" style={{ color: "#dc3545" }}>
                    {this.props.data.ErrorMsgValidate}
                  </label>
                )}

                {this.props.data.Name.length > 0 && (
                  <React.Fragment>
                    <h2>Check your current information below and proceed</h2>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Name: {this.props.data.Name}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Semester: {this.props.data.CurrentSemester}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Level: {this.props.data.CurrentLevel}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Campus: {this.props.data.CurrentCampus}
                          </label>
                        </div>
                      </div>

                      {studentClasses}
                    </div>

                    <h2>Personal Information</h2>
                    <label className="label-input">
                      * Federal regulations require that you report a change of
                      address to a DSO to maintain your student status. If you
                      move to a new residence and your address changes, you must
                      report this to your DSO within 10 days of the change.{" "}
                      <a
                        style={{ fontWeight: "bold", color: "red" }}
                        href="https://studyinthestates.dhs.gov/2016/03/students-ensure-your-address-correct-sevis#:~:text=Federal%20regulations%20require%20that%20you,10%20days%20of%20the%20change."
                        target="_blank"
                      >
                        USCIS - Click here
                      </a>
                    </label>

                    <div
                      className="row form-inner-area"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Email</label>

                          <input
                            maxLength={"100"}
                            type="email"
                            className={`form-control ${
                              invalidEmail === true ? " input-error " : ""
                            }`}
                            name="Email"
                            value={this.props.data.Email}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step1"
                            )}
                            onBlur={this.props.emailOnBlur.bind(this)}
                          />
                          {invalidEmail === true && (
                            <span className="text-danger">Invalid e-mail</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Phone Number</label>
                          <input
                            maxLength={"45"}
                            type="text"
                            className={
                              phoneNumberIsValid
                                ? "form-control"
                                : "form-control invalid"
                            }
                            name="PhoneNumber"
                            value={this.props.data.PhoneNumber}
                            onChange={this.props.handlePhoneNumberChange.bind(
                              this
                            )}
                          />
                          {!phoneNumberIsValid && (
                            <span className="text-danger">
                              Please enter a valid phone number
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Street Address</label>
                          <input
                            maxLength={"100"}
                            type="text"
                            className="form-control"
                            name="Street"
                            value={this.props.data.Street}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step1"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Address Line 2</label>
                          <input
                            maxLength={"100"}
                            type="text"
                            className="form-control"
                            name="Street2"
                            value={this.props.data.Street2}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step1"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">City</label>
                          <input
                            maxLength={"45"}
                            type="text"
                            className="form-control"
                            name="City"
                            value={this.props.data.City}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step1"
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">State</label>

                          <div className="language-select">
                            <select
                              onChange={this.props.handleSelectStateChange.bind(
                                this
                              )}
                              value={this.props.data.State}
                            >
                              <option value="">Select</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Postal / Zip Code
                          </label>
                          <input
                            maxLength={"45"}
                            type="text"
                            className="form-control"
                            name="Zip"
                            value={this.props.data.Zip}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step1"
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Country"
                            value={"United States"}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>

                    <h2>What is your plan for the next semester?</h2>

                    <div className="services-select-option">
                      <ul>
                        <div onClick={this.handleScroll.bind(this, 1)}>
                          <li className="bg-white ">
                            <label>
                              Keep studying
                              <input type="radio" name="plan" />
                            </label>
                          </li>
                        </div>
                        {this.props.data.CurrentStatus.toUpperCase() !==
                          "BREAK" && (
                          <div onClick={this.handleScroll.bind(this, 2)}>
                            <li className="bg-white">
                              <label>
                                Take a break
                                <input type="radio" name="plan" />
                              </label>
                            </li>
                          </div>
                        )}
                        <div onClick={this.handleScroll.bind(this, 3)}>
                          <li className="bg-white">
                            <label>
                              Transfer to college
                              <input type="radio" name="plan" />
                            </label>
                          </li>
                        </div>
                        <div onClick={this.handleScroll.bind(this, 4)}>
                          <li className="bg-white">
                            <label>
                              Other
                              <input type="radio" name="plan" />
                            </label>
                          </li>
                        </div>

                        {this.props.data.PlanId === 4 && (
                          <div className="row form-inner-area">
                            <div className="col-md-12">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <label className="label-input">Reason</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="OtherReason"
                                  value={this.props.data.OtherReason}
                                  onChange={this.props.handleUserInput.bind(
                                    this,
                                    "Step1"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>

                    {this.props.data.PlanId === 1 && (
                      <React.Fragment>
                        <h2>Which campus do you plan to study at?</h2>

                        <div className="services-select-option3">
                          <ul>
                            <div
                              onClick={this.handleScrollCampus.bind(this, 2)}
                            >
                              <li className="bg-white">
                                <label>
                                  Orem
                                  <input type="radio" name="campus" />
                                </label>
                              </li>
                            </div>
                            <div
                              onClick={this.handleScrollCampus.bind(this, 3)}
                            >
                              <li className="bg-white ">
                                <label>
                                  Salt Lake City
                                  <input type="radio" name="campus" />
                                </label>
                              </li>
                            </div>
                          </ul>
                        </div>

                        {this.props.data.CampusId > -1 && (
                          <React.Fragment>
                            {" "}
                            <h2>
                              Here are the classes available in{" "}
                              <strong
                                style={{
                                  color: "#f58220",
                                  fontStyle: "bold",
                                }}
                              >
                                {this.props.data.CampusId == 2 ? "Orem" : "SLC"}
                              </strong>
                            </h2>
                            <div className="services-select-option4 ">
                              <ul>
                                {subjects}
                                {/* <div
                                  onClick={this.props.periodRender.bind(
                                    this,
                                    3
                                  )}
                                >
                                  <li className="bg-white customRadio">
                                    <label>
                                      <h3>
                                        Super Intensive{" "}
                                        <span
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            padding: "2px 8px",
                                            borderRadius: "5px",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          New
                                        </span>
                                      </h3>
                                    </label>
                                    <label>Monday and Tuesday</label>
                                    <label>
                                      8:00 AM to 5:00 PM, 2x week{" "}
                                      <input type="radio" name="period" />
                                    </label>
                                  </li>
                                </div> */}

                                {/* <div
                                  onClick={this.props.periodRender.bind(
                                    this,
                                    4
                                  )}
                                >
                                  <li className="bg-white customRadio">
                                    <label>
                                      <h3>
                                        Super Intensive{" "}
                                        <span
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            padding: "2px 8px",
                                            borderRadius: "5px",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          New
                                        </span>
                                      </h3>
                                    </label>
                                    <label>Wednesday and Thursday</label>
                                    <label>
                                      8:00 AM to 5:00 PM, 2x week{" "}
                                      <input type="radio" name="period" />
                                    </label>
                                  </li>
                                </div> */}

                                {/* <div
                                  onClick={this.props.periodRender.bind(
                                    this,
                                    1
                                  )}
                                >
                                  <li className="bg-white customRadio">
                                    <label>
                                      <h3>Morning</h3>
                                    </label>
                                    <label>Monday to Thursday</label>
                                    <label>
                                      9:00 AM to 1:30 PM, 4x week{" "}
                                      <input type="radio" name="period" />
                                    </label>
                                  </li>
                                </div> */}

                                {/* <div
                                  onClick={this.props.periodRender.bind(
                                    this,
                                    2
                                  )}
                                >
                                  <li className="bg-white customRadio">
                                    <label>
                                      <h3>Evening</h3>
                                    </label>
                                    <label>Monday to Thursday</label>
                                    <label>
                                      5:00 PM to 9:30 PM, 4x week{" "}
                                      <input type="radio" name="period" />
                                    </label>
                                  </li>
                                </div> */}
                              </ul>
                            </div>
                            <div className="services-select-option2">
                              <ul>
                                {this.props.data.Failed === false &&
                                  ((this.props.data.CurrentOrder < 6 &&
                                    this.props.Subject?.courseOrder <=
                                      this.props.data.CurrentOrder) ||
                                    (this.props.data.CurrentOrder >= 6 &&
                                      this.props.Subject?.courseOrder ==
                                        5)) && (
                                    <div className="row form-inner-area">
                                      <div className="col-md-12">
                                        <div className="wizard-form-input position-relative form-group has-float-label">
                                          <label className="label-input">
                                            Justify
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="LevelReason"
                                            value={this.props.data.LevelReason}
                                            onChange={this.props.handleUserInput.bind(
                                              this,
                                              "Step1"
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </ul>
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}

                    <div
                      ref={(el) => {
                        this.el = el;
                      }}
                    ></div>

                    {(this.props.data.PlanId === 2 ||
                      this.props.data.PlanId === 3 ||
                      this.props.data.PlanId === 4) && (
                      <div className="budget-area">
                        <br />

                        <div
                          className="row form-inner-area"
                          style={{ marginTop: "2rem" }}
                        >
                          <div className="col-md-6">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label className="label-input">Signature</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="wizard-form-input position-relative form-group has-float-label float-right">
                              <button
                                color="danger"
                                className="btn btn-danger "
                                onClick={this.clear.bind(this)}
                              >
                                Clear Signature
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row form-inner-area">
                          <div className="col-md-12">
                            <div className="sigContainer">
                              <SignaturePad
                                canvasProps={{
                                  className: "sigPad",
                                }}
                                ref={(ref) => {
                                  this.sigPad = ref;
                                }}
                                onEnd={this.trim.bind(this)}
                                clearOnResize={false}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="row form-inner-area"
                          style={{ marginTop: "2rem" }}
                        >
                          <div className="col-md-2">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label className="label-input">Initials</label>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="wizard-form-input position-relative form-group has-float-label float-right">
                              <button
                                color="danger"
                                className="btn btn-danger "
                                onClick={this.clearInitials.bind(this)}
                              >
                                Clear Initials
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row form-inner-area"
                          style={{ marginBottom: "1rem" }}
                        >
                          <div className="col-md-4">
                            <div className="sigContainer">
                              <SignaturePad
                                canvasProps={{
                                  className: "sigPad",
                                }}
                                ref={(ref) => {
                                  this.initialsPad = ref;
                                }}
                                onEnd={this.trimInitials.bind(this)}
                                clearOnResize={false}
                              />
                            </div>
                          </div>
                        </div>

                        <span
                          style={{
                            fontStyle: "italic",
                            color: "gray",
                            textAlign: "justify",
                          }}
                        >
                          By signing, I hereby certify that the above
                          information, to the best of my knowledge, is correct.
                          I understand and agree to abide by all the provisions
                          set forth in the foregoing enrollment agreement as
                          presented in the school catalog.
                        </span>

                        {this.props.data.ErrorSubmit.length > 0 && (
                          <div
                            className="row form-inner-area"
                            style={{ marginTop: "2rem" }}
                          >
                            <div className="col-md-12">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <div class="alert alert-danger" role="alert">
                                  {this.props.data.ErrorSubmit}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          {/* <div className="actions" style={{ marginTop: "2rem" }}>
            <ul>
              <li className="disable" aria-disabled="true">
                <span className="js-btn-next" title="NEXT">
                  Backward <i className="fa fa-arrow-right" />
                </span>
              </li>
              <li>
                <span className={`js-btn-next ${""}`} title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div> */}

          {this.props.data.PlanId === 1 && (
            <div className="actions" style={{ marginTop: "2rem" }}>
              <ul>
                <li className="disable" aria-disabled="true">
                  <span className="js-btn-next" title="NEXT">
                    Backward <i className="fa fa-arrow-right" />
                  </span>
                </li>
                <li>
                  <span
                    className={`js-btn-next ${
                      this.formValid() ? "" : "button-disabled"
                    }`}
                    title="NEXT"
                  >
                    NEXT <i className="fa fa-arrow-right" />
                  </span>
                </li>
              </ul>
            </div>
          )}

          {(this.props.data.PlanId === 2 ||
            this.props.data.PlanId === 3 ||
            this.props.data.PlanId === 4) && (
            <React.Fragment>
              <div className="actions" style={{ marginTop: "2rem" }}>
                <ul>
                  <li className="disable" aria-disabled="true">
                    <span className="js-btn-next" title="NEXT">
                      Backward <i className="fa fa-arrow-right" />
                    </span>
                  </li>
                  <li>
                    <span
                      className={` ${
                        this.formValid() &&
                        this.props.data.SubmittingApplication === false
                          ? ""
                          : "button-disabled"
                      }`}
                      title="SUBMIT"
                      disabled={this.props.data.SubmittingApplication === true}
                      onClick={this.props.handleSubmitBreak.bind(this)}
                    >
                      SUBMIT <i className="fa fa-check" />
                    </span>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.Name.length > 0 &&
      this.props.data.Email.length > 0 &&
      this.props.data.InvalidEmail === false &&
      this.props.data.PhoneNumber.length > 0 &&
      this.props.data.PhoneNumberIsValid === true &&
      this.props.data.Street.length > 0 &&
      this.props.data.City.length > 0 &&
      this.props.data.State.length > 0 &&
      this.props.data.Zip.length > 0 &&
      this.props.data.Country.length > 0 &&
      (((this.props.data.PlanId === 2 ||
        this.props.data.PlanId === 3 ||
        (this.props.data.PlanId === 4 &&
          this.props.data.OtherReason.length > 0)) &&
        this.props.data.trimmedDataURL !== null &&
        this.props.data.trimmedDataURL.length > 0 &&
        this.props.data.trimmedDataURLInitials !== null &&
        this.props.data.trimmedDataURLInitials.length > 0) ||
        (this.props.data.PlanId === 1 &&
          this.props.data.CampusId > -1 &&
          this.props.data.SubjectId.length > 0 &&
          ((this.props.data.Failed === false &&
            this.props.data.CurrentOrder < 6 &&
            this.props.Subject?.courseOrder <= this.props.data.CurrentOrder &&
            this.props.data.LevelReason.length > 0) ||
            (this.props.data.Failed === false &&
              this.props.data.CurrentOrder < 6 &&
              this.props.Subject?.courseOrder > this.props.data.CurrentOrder) ||
            (this.props.data.Failed === false &&
              this.props.data.CurrentOrder >= 6))))
    );
  }

  handleScroll(id) {
    this.el.scrollIntoView({ behavior: "smooth" });

    this.props.planRender(id);
  }

  handleScrollCampus(id) {
    this.el.scrollIntoView({ behavior: "smooth" });

    this.props.campusRender(id);
  }

  handleScrollSubject(id) {
    this.el.scrollIntoView({ behavior: "smooth" });

    this.props.subjectRender(id);
  }
}

export default StepOne;
