import React, { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

import VersionOne from "./components/version-one";

const getBasename = (path) => path.substr(0, path.lastIndexOf("/"));

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router basename={getBasename(window.location.pathname)}>
          <Switch>
            <Route path="/" exact component={VersionOne} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
